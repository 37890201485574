import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import TopMenu from "./components/Topmenu/TopMenu";
import Carte from "./components/mainpages/Carte";
import Contact from "./components/mainpages/Contact";
import Canot from "./components/mainpages/Canot";
import { v4 as uuidv4 } from "uuid";
import Kayak from "./components/mainpages/Kayak";
import Sup from "./components/mainpages/Sup";
import CanotCamping from "./components/mainpages/CanotCamping";
import TraineauChien from "./components/mainpages/TraineauChien";
import TraineauChienVIP from "./components/mainpages/TraineauChienVIP";
import RaquetteTractee from "./components/mainpages/RaquetteTractee";
import YourteOneNight from "./components/mainpages/YourteOneNight";
import AuthProvider from "./contexts/AuthProvider";
import PackagesProvider from "./contexts/PackagesProvider";
import Reserver from "./components/booking/Reserver";
import VenteYourte from "./components/mainpages/VenteYourte";
import { Helmet } from "react-helmet";
import NotFound from "./components/helper/NotFound";
import JourneeList from "./components/merchantCenter/JourneeList";
import MasterList from "./components/merchantCenter/MasterList";
import ResaList from "./components/merchantCenter/ResaList";
const App: React.FC = () => {
  // ***-------------------Booking Modal------------------------------------***
  const refBookingBox = useRef<HTMLDivElement>(null);
  const [bookingDisplay, setBookingDisplay] = useState(false);

  useEffect(() => {
    if (bookingDisplay) {
      refBookingBox.current!.className =
        "absolute -top-[700px] opacity-0 justify-center border-2 sm:mx-64 z-10  ";
      setTimeout(() => {
        refBookingBox.current!.className =
          "absolute top-24 justify-center border-2 sm:mx-64 z-40 transition-all w-[100%] -mt-2 sm:mt-0 sm:w-auto duration-500 ease-out";
      }, 100);
    }
    if (!bookingDisplay) {
      refBookingBox.current!.className =
        "absolute -top-[700px] opacity-0 justify-center border-2 sm:mx-64  transition-all z-0 duration-500  ease-in";
      setTimeout(() => {
        refBookingBox.current!.className = " hidden";
      }, 500);
    }
  }, [bookingDisplay]);

  const hideShowBookingBox = (): void => {
    setBookingDisplay(!bookingDisplay);
  };
  // END-------------------Booking Modal-----------------------------------***
  return (
    <div>
      <Helmet>
        <meta httpEquiv="Content-type" content="text/html;charset=UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta
          name="title"
          content="Canot, kayak, SUP, Traineau à chiens Rivière-Rouge Mont-Tremblant Laurentides"
        />
        <meta
          name="description"
          content="Location de canot, kayak et Traineau à chiens sur la Rivière-Rouge, Mont-Tremblant - Laurentides : la Rivière-Rouge. Promenades en traîneau à chiens. Nuitées en yourte."
        />
        <meta name="Copyright" content="Aventure Quatre Saisons enr." />
        <meta name="revisit-after" content=" 5 days" />
        <meta name="author" content="NOK" />
        <meta name="type" content="website" />
        <meta name="url" content="https://www.aventurequatresaisons.com" />
        <meta name="site_name" content="Aventure Quatre Saisons"></meta>
        <meta
          name="Classification"
          content="Attraction touristique, Service de location de canots et kayaks, Service de promenades en traîneau à chiens, Agence de voyages de sports de pagaie, Gîte touristique, Site de camping"
        />
        <meta property="og:url" content="https://www.aventurequatresaisons.com" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Canot, kayak, SUP et Traineau à chiens Rivière-Rouge Mont-Tremblant Laurentides"
        />
        <meta
          property="og:description"
          content="location de canot, kayak, SUP et Traineau à chiens sur la Rivière-Rouge, Mont-Tremblant - Laurentides : la Rivière-Rouge."
        />
        <meta
          property="og:image"
          content="https://www.aventurequatresaisons.com/images/ogaccueil.jpg"
        />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <title>
          Canot, kayak, SUP et Traineau à chiens Rivière-Rouge Mont-Tremblant Laurentides
        </title>
      </Helmet>
      <Router>
        <div>
          <div className="flex">
            <div id="toastParent" className="flex z-0 fixed justify-center top-2/4  w-full">
              <div id="toast" className="opacity-0">
                <div id="message">
                  Message to be deliver, lorem ipsum.. Message to be deliver, lorem ipsum.. Message
                  to be deliver, lorem ipsum.. Message to be deliver, lorem ipsum..
                </div>
                <div className="flex justify-center">
                  <div
                    id="toastButton"
                    className="flex justify-center border-2 w-20 rounded-md text-2xl p-1 hover:cursor-pointer hover:bg-green/50 duration-500">
                    Got it!
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AuthProvider>
            <PackagesProvider>
              <div className="flex h-auto">
                <TopMenu
                  key={uuidv4()}
                  bookingState={false}
                  dropDownState={false}
                  hideShowBookingBox={hideShowBookingBox}
                />
              </div>
              {/* ----------------------------------Booking Component--------------------------------------------- */}
              <div className="hidden" id="booking" ref={refBookingBox}>
                <Reserver hideBookingBox={hideShowBookingBox}></Reserver>
              </div>
              {/* END-------------------------------Booking Component-------------------------------------------- */}
              <div className="flex bg-gradient-to-b to-aqs-green-900 from-aqs-green-50/20">
                <Routes>
                  <Route path="/" element={<Canot hideShowBookingBox={hideShowBookingBox} />} />
                  <Route
                    path="/fr/laurentides/riviere-rouge/ete/"
                    element={<Canot hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route
                    path="/fr/laurentides/riviere-rouge/canot/"
                    element={<Canot hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route
                    path="/fr/laurentides/riviere-rouge/kayak/"
                    element={<Kayak hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route
                    path="/fr/laurentides/riviere-rouge/standup-paddleboard/"
                    element={<Sup hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route
                    path="/fr/laurentides/riviere-rouge/canot-camping/"
                    element={<CanotCamping hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route
                    path="/fr/laurentides/riviere-rouge/hiver/"
                    element={<TraineauChien hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route
                    path="/fr/laurentides/riviere-rouge/traineauachiens/"
                    element={<TraineauChien hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route
                    path="/fr/laurentides/riviere-rouge/traineauachiens-vip/"
                    element={<TraineauChienVIP hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route
                    path="/fr/laurentides/riviere-rouge/randonnee-tractee/"
                    element={<RaquetteTractee hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route
                    path="/fr/laurentides/riviere-rouge/yourte/"
                    element={<YourteOneNight hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route
                    path="/fr/laurentides/riviere-rouge/nuiteeenyourte/"
                    element={<YourteOneNight hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route
                    path="/fr/laurentides/riviere-rouge/2nuiteeenyourte/"
                    element={<YourteOneNight hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route
                    path="/fr/laurentides/riviere-rouge/3nuiteeenyourte/"
                    element={<YourteOneNight hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route
                    path="/fr/laurentides/riviere-rouge/ventedeyourte"
                    element={<VenteYourte hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route
                    path="/fr/laurentides/riviere-rouge/carte"
                    element={<Carte hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route path="/fr/laurentides/riviere-rouge/contact" element={<Contact />} />
                  <Route path="/fr/laurentides/riviere-rouge/merchant/" element={<JourneeList />} />
                  <Route
                    path="/fr/laurentides/riviere-rouge/merchant/booking"
                    element={<ResaList />}
                  />
                  <Route
                    path="/fr/laurentides/riviere-rouge/merchant/inventory"
                    element={<JourneeList />}
                  />
                  <Route
                    path="/fr/laurentides/riviere-rouge/merchant/master"
                    element={<MasterList />}
                  />

                  {/* --------------------ENGLICH ROUTES-------------------- */}

                  <Route
                    path="/en/laurentides/riviere-rouge/ete/"
                    element={<Canot languageUrl="Eng" hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route
                    path="/en/laurentides/riviere-rouge/canot/"
                    element={<Canot languageUrl="Eng" hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route
                    path="/en/laurentides/riviere-rouge/kayak/"
                    element={<Kayak languageUrl="Eng" hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route
                    path="/en/laurentides/riviere-rouge/standup-paddleboard/"
                    element={<Sup languageUrl="Eng" hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route
                    path="/en/laurentides/riviere-rouge/canot-camping/"
                    element={
                      <CanotCamping languageUrl="Eng" hideShowBookingBox={hideShowBookingBox} />
                    }
                  />
                  <Route
                    path="/en/laurentides/riviere-rouge/hiver/"
                    element={
                      <TraineauChien languageUrl="Eng" hideShowBookingBox={hideShowBookingBox} />
                    }
                  />
                  <Route
                    path="/en/laurentides/riviere-rouge/traineauachiens/"
                    element={
                      <TraineauChien languageUrl="Eng" hideShowBookingBox={hideShowBookingBox} />
                    }
                  />
                  <Route
                    path="/en/laurentides/riviere-rouge/traineauachiens-vip/"
                    element={
                      <TraineauChienVIP languageUrl="Eng" hideShowBookingBox={hideShowBookingBox} />
                    }
                  />
                  <Route
                    path="/en/laurentides/riviere-rouge/randonnee-tractee/"
                    element={
                      <RaquetteTractee languageUrl="Eng" hideShowBookingBox={hideShowBookingBox} />
                    }
                  />
                  <Route
                    path="/en/laurentides/riviere-rouge/yourte/"
                    element={
                      <YourteOneNight languageUrl="Eng" hideShowBookingBox={hideShowBookingBox} />
                    }
                  />
                  <Route
                    path="/en/laurentides/riviere-rouge/nuiteeenyourte/"
                    element={
                      <YourteOneNight languageUrl="Eng" hideShowBookingBox={hideShowBookingBox} />
                    }
                  />
                  <Route
                    path="/en/laurentides/riviere-rouge/2nuiteeenyourte/"
                    element={
                      <YourteOneNight languageUrl="Eng" hideShowBookingBox={hideShowBookingBox} />
                    }
                  />
                  <Route
                    path="/en/laurentides/riviere-rouge/3nuiteeenyourte/"
                    element={
                      <YourteOneNight languageUrl="Eng" hideShowBookingBox={hideShowBookingBox} />
                    }
                  />
                  <Route
                    path="/en/laurentides/riviere-rouge/ventedeyourte"
                    element={<VenteYourte hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route
                    path="/en/laurentides/riviere-rouge/carte"
                    element={<Carte languageUrl="Eng" hideShowBookingBox={hideShowBookingBox} />}
                  />
                  <Route path="/en/laurentides/riviere-rouge/contact" element={<Contact />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </div>
            </PackagesProvider>
          </AuthProvider>
        </div>
      </Router>
    </div>
  );
};
export default App;
