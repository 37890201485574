import { activities } from "../../types/activityType";
import { defaultContent } from "../../types/defaultContent";
import { packages } from "../../types/packagesTypes";
import { reservation } from "../../types/reservationTypes";
import { loginUser, user } from "../../types/userType";

export const contentTextDefault: defaultContent[] = [
  {
    name: "mission",
    language: "fr",
    title: "Vous voulez une aventure nature DEFAULT... \n Près du Mont-Tremblant !",
    subtitle: "Sortez ENFIN avec une descente sur la Rivière-Rouge!",
    description:
      "Les Laurentides et la région de Mont-Tremblant valent absolument le détour! " +
      "Pour le nautique, que ce soit pour faire du canot, du rafting, du kayak ou du stand-up paddleboard( SUP )! " +
      "La descente de la Rivière Rouge est une activité nautique attrayante et fougueuse qui fait le bonheur de nombreux canoteurs et " +
      "kayakistes année après année. Aventure Quatre Saisons vous propose des descentes en rivière pour tous. " +
      "Que ce soit en formule 15 km ou encore en canot-camping de 2 à 5 jours sur les plages de la Rivière-Rouge, nous avons ce que vous cherchez! " +
      "Un paysage à couper le souffle agrémenté d'une touche d'exotisme grâce à la présence de nos yourtes importées de Mongolie! " +
      "Chez nous, vous avez le choix! : Traîneau à chiens, Rafting, Canot, Kayak sans oublier la nouvelle vedette de l’heure, Le SUP (Stand-up Paddleboard)",
    descriptionFollowUp: "",
    secondTitle: "",
    secondSubtitle: "Profitez de l'hiver avec nos forfaits de traîneau à chiens!",
    secondDescription:
      "Naviguez à travers nos différents forfaits afin de voir l'étendue de ce que nos athlètes adorés sont capables de faire!",
    thirdTitle: "",
    thirdSubtitle: "",
    thirdDescription: ""
  },

  {
    name: "bookingPolicy",
    language: "fr",
    title: "Comment bien planifier votre activité chez nous ?",
    subtitle: "Politique de réservation",
    description:
      "Afin de bien pouvoir planifier votre arrivée et offrir des séjours de qualités, Aventure Quatre Saisons demande une réservation pour tous les séjours. " +
      "Lors de votre réservation, un mode de paiement vous est demandé et vous devrez obligatoirement fournir un numéro valide de carte de crédit afin de pouvoir " +
      "prendre la totalité de la valeur du séjour. Ce montant n'est pas remboursable. Si vous devez annuller/modifier votre activité, le total ne sera pas remboursé.",
    descriptionFollowUp:
      "Svp assurez-vous que votre carte est valide jusqu'à la date de votre sortie.",
    secondTitle: "Annulation d'un séjour",
    secondSubtitle: "",
    secondDescription:
      "Le montant total de la prestation est non-remboursable et vous sera facturé à la réservation.",
    thirdTitle: "",
    thirdSubtitle: "",
    thirdDescription: ""
  },
  {
    name: "comments",
    language: "fr",
    title: "Bien encadré !",
    subtitle:
      "LA place pour vos vacances et surtout le traineau à chiens, ne cherchez plus c'est là que ça se passe! " +
      "Merci à toute l'équipe vous avez fait un travail remarquable. Et Quels chiens ! À bientôt",
    description: "-Matt R",
    descriptionFollowUp: "",
    secondTitle: "",
    secondSubtitle: "",
    secondDescription: "",
    thirdTitle: "",
    thirdSubtitle: "",
    thirdDescription: ""
  },
  {
    name: "yourteSell",
    language: "fr",
    title: "Achetez une yourte : Un choix qui tourne rond !",
    subtitle:
      "La yourte est l'habitation traditionnelle des peuples nomades vivant en Asie Centrale : Mongoles, Kazakhs, Kirghizes... Plus qu’une tente, " +
      "la yourte est le fruit d’un savoir-faire millénaire ancestrale. Cette habitation est conçue afin de résister aux climats les plus extrêmes. " +
      "Chaude en hiver et fraîche en été, la structure se démonte afin de pouvoir être déplacée facilement avec des yacks, des chevaux ou des chameaux. " +
      "Pour nous, occidentaux, elle peut-être déplacer facilement à l'aide d'une petite remorque.",
    description:
      "Cette ingénieuse structure ne nécessite aucun clou et aucun ancrage au sol ! Ses murs sont des treillis de bois pliables assemblés en cercle " +
      "avec des cordes de crin. Au centre, un dôme appelée tonoo est dressée sur deux piliers afin de soutenir la charpente constituée de perches. " +
      "C'est le nombre de murs en treillit qui détermine la surface au sol de la yourte. La yourte se chauffe facilement avec un foyer combustion lente " +
      "et devient confortable très rapidement en hiver. Le fléau de la yourte est en fait l'eau. C'est pourquoi il est important que le montage soit fait " +
      "avec soin et que la yourte soit bien entretenue. En hiver, la yourte demande très peu d'attention. Même si la yourte peut recevoir une très bonne charge " +
      "de neige, il est conseillé de déneiger le toit régulièrement.",
    descriptionFollowUp:
      "Le tout est recouvert de feutre à base de laine de moutons et d’un canevas de coton résistant à l'eau. Selon sa taille, " +
      "il faut environ 5 h et 2 personnes pour monter une yourte. Quatre saisons, cette tente de nomades mongoles s’intègre dans tous les paysages. " +
      "Pour des fêtes, des spectacles, comme chambre d’hôte, comme habitat alternatif, comme cabane de chasse, pour y vendre du vin chaud sur une piste " +
      "de ski ou simplement pour y passer des moments en famille ou entre amis.",
    secondTitle: "Nos yourtes authentiques sont de très haute qualité !",
    secondSubtitle:
      "Importées directement de Mongolie, nos yourtes sont faites presque entièrement à la main. Bois peint, feutre et toile décorée de haute " +
      "qualité ainsi que cordes tressées en crin de cheval font de nos yourtes un produit haut de gamme apprécié de plusieurs. Elles peuvent être " +
      "livrées avec le mobilier traditionnel. Une grande partie du matériel que nous importons provient directement des zones rurales de Mongolie " +
      "afin d'aider à freiner l'exode, un fléau dans ce pays de nomades.",
    secondDescription:
      "La structure, c’est-à-dire le tonoo, la porte, les piliers centraux et les perches sont peintes à la main avec les motifs traditionnels " +
      "mongols. Sa teinte orangée ornée de motifs verts, bleus et roses donne une atmosphère colorée et chaleureuse à l'intérieur de la yourte. " +
      "Sortant un peu de la tradition, nos yourtes peuvent également être commandées dans d'autres couleurs, avec les mêmes motifs. " +
      "De plus, Nos yourtes sont parfaitement adaptées au climat québécois !",
    thirdTitle: "",
    thirdSubtitle: "",
    thirdDescription:
      "En Mongolie, les yourtes sont installées directement sur le sol. Au Québec, le climat plus humide demande quelques adaptations " +
      "spéciales qui prolongerons la durée de vie de votre yourte. Par exemple, nous avons dévellopés des plancher isolés R10 qui sont parfaitements " +
      "adaptés à nos yourtes. De forme ronde, ces planchers sont démontables facilement et peuvent être déposés simplement sur des blocs de ciments. " +
      "Nous recommandons fortement leurs utilisations de sorte à avoir une yourte à toutes épreuves. De base, les dôme de yourtes possède 4 insères " +
      "qui recouvrent la moitié de la surface. Ainsi, en cas de pluie, vous devez rabattre une pièce qui se nomme le hursh. De sorte à éviter de " +
      "telles manipulations parfois redondantes, nous offrons en options de finir le dôme en lexan, un produit très durable qui laisse passer 30% " +
      "plus de lumière qu'une vitre normale."
  }
];
export const summerActivities: activities[] = [
  {
    name: "Canot",
    pricing: 49,
    activityType: "summer",
    description:
      "Si vous n'êtes que de passage dans la région, ce forfait est idéal pour vous ! \n" +
      "Cette descente en eau calme vous permettra de canoter paisiblement sur la Rivière Rouge et de profiter allègrement de nos deux îles de sable blond. " +
      "\n\nNotez que cette descente de quinze kilomètres est parfaite pour tous les gens qui souhaitent une aventure douce et paysible. Avec ses paysages sauvages, la rivière-rouge vous comblera et vous inspirera longtemps après votre départ! Prenez le temps que vous souhaitez pour descendre la rivière car vous terminerez à votre voiture. " +
      "\n\nLa navette incluse se fait au début de l'activité. Un bain de soleil ou une pause pique nique sur les nombreuses plages de sable blond agrémenteront l'expérience. L'activité parfaite pour profiter pleinement d'une belle journée d'été.",
    inclusions:
      "-Canot (Duo)\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    boatPlace: "DUO",
    departureHour: "9h, 10h, 11h, 13h",
    duration: "15km, 25km, Canot-Camping(25km, 34km)",
    picUrl: "https://www.aventurequatresaisons.com/images/canot15km.jpg",
    URLPointer: "/fr/laurentides/riviere-rouge/canot/"
  },
  {
    name: "Kayak",
    pricing: 49,
    activityType: "summer",
    description:
      "Si vous n'êtes que de passage dans la région, ce forfait est idéal pour vous ! \n" +
      "Cette descente en eau calme vous permettra de faire du kayak paisiblement sur la Rivière Rouge et de profiter allègrement de nos deux îles de sable blond. " +
      "\n\nNotez que cette descente de quinze kilomètres est parfaite pour tous les gens qui souhaitent une aventure douce et paysible. Avec ses paysages sauvages, la rivière-rouge vous comblera et vous inspirera longtemps après votre départ! Prenez le temps que vous souhaitez pour descendre la rivière car vous terminerez à votre voiture. " +
      "\n\nLa navette incluse se fait au début de l'activité. Un bain de soleil ou une pause pique nique sur les nombreuses plages de sable blond agrémenteront l'expérience. L'activité parfaite pour profiter pleinement d'une belle journée d'été.",
    inclusions:
      "-Kayak (Solo seulement)\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    boatPlace: "SOLO",
    departureHour: "10h, 11h, 13h",
    duration: "15km, 25km",
    picUrl: "https://www.aventurequatresaisons.com/images/kayakact.jpg",
    URLPointer: "/fr/laurentides/riviere-rouge/kayak/"
  },
  {
    name: "StandUp Paddle",
    pricing: 49,
    activityType: "summer",
    description:
      "Si vous n'êtes que de passage dans la région, ce forfait est idéal pour vous ! \n" +
      "Cette descente en eau calme vous permettra de faire du SUP paisiblement sur la Rivière Rouge et de profiter allègrement de nos deux îles de sable blond. " +
      "\n\nNotez que cette descente de quinze kilomètres est parfaite pour tous les gens qui souhaitent une aventure douce et paysible. Avec ses paysages sauvages, la rivière-rouge vous comblera et vous inspirera longtemps après votre départ! Prenez le temps que vous souhaitez pour descendre la rivière car vous terminerez à votre voiture. " +
      "\n\nLa navette incluse se fait au début de l'activité. Un bain de soleil ou une pause pique nique sur les nombreuses plages de sable blond agrémenteront l'expérience. L'activité parfaite pour profiter pleinement d'une belle journée d'été.",
    inclusions:
      "-PaddleBoard (Solo seulement)\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    boatPlace: "SOLO",
    departureHour: "10h, 11h, 13h",
    duration: "15km",
    picUrl: "https://www.aventurequatresaisons.com/images/supact.jpg",
    URLPointer: "/fr/laurentides/riviere-rouge/standup-paddleboard/"
  },
  {
    name: "Canot-Camping",
    pricing: 98,
    activityType: "summer",
    description:
      "Partez a l'aventure sur 25 km d'eau calme de rivière à bord de votre canot. Ce forfait permet de profiter davantage du site de campement avec moins de km a faire en canot." +
      "Muni de votre tente, installez-vous sur l'une des nombreuses plages magestueuses de la Rivière-Rouge." +
      "Connue pour ses dunes de sable blond, cette section de rivière vous offrira de nombreux paysages a couper le souffle tout au long de votre séjour. Le séjour inclu un petit rapide de classe R1 de 150 mètre ",
    inclusions:
      "-Canot (Duo)\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    boatPlace: "DUO",
    departureHour: "9h, 10h",
    duration: "25km, 34km",
    picUrl: "https://www.aventurequatresaisons.com/images/canotcampingact.jpg",
    URLPointer: "/fr/laurentides/riviere-rouge/canot-camping/"
  }
];

export const winterActivities: activities[] = [
  {
    name: "Traineau à chiens",
    pricing: 129,
    activityType: "winter",
    description:
      "La randonnée de une heures permet aux participants de conduire leur propre embarcation en alternance et d'aller voir les sentiers plus techniques." +
      "Accompagnés par un guide qui ouvre la route, les chiens sillonnent des sentiers boisés et le champ pour un parcours féérique." +
      "La neige alourdissant les branches des arbres fait un paysage incroyable digne de nos belles Laurentides." +
      "Les chiens motivés quant à eux apprécieront vos caresses à la fin de la randonnée.\n" +
      "***Vous vous déplacer pour presque 2 heures d'activité avec tous ce qui entoure votre sortie! Est-ce que les chiots sauront vous charmer !",
    inclusions: "-Stationnement\n" + "-Matériel pour activité\n" + "-Accès\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Lunettes de ski\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    boatPlace: "DUO",
    departureHour: "9h, 11h, 13h",
    duration: "1h",
    picUrl: "https://www.aventurequatresaisons.com/images/traineau1h.jpg",
    URLPointer: "/fr/laurentides/riviere-rouge/traineauachiens/"
  },
  {
    name: "Traineau à chiens -VIP-",
    pricing: 269,
    activityType: "winter",
    description:
      "Cette nouvelle formule vous invite à venir passer la journée dehors avec nous !" +
      "Location de raquettes en avant-midi pour sillonner les sentiers ou parcourir la poudreuse hors piste." +
      "À midi, une soupe est servie dans notre yourte chauffée au poêle à bois histoire de reprendre des" +
      "forces.\nRandonnée d’une heure de traîneau à chiens en après-midi suite à quoi vous pourrez en apprendre" +
      "d'avantage sur l'univers du musher en servant, si vous le souhaitez, la soupe de viande aux chiens" +
      "après la randonnée.\n\n\n***Vous vous déplacer pour presque 3 heures d'activité avec tous ce qui entoure " +
      "votre sortie! Un séjour prolongé !",
    inclusions: "-Soupe-Repas\n" + "-Stationnement\n" + "-Matériel pour activité\n" + "-Accès\n",
    exclusions: "-Effets Personnel\n",
    stuffToBring: "-Lunettes de ski\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    boatPlace: "DUO",
    departureHour: "13h",
    duration: "1h",
    picUrl: "https://www.aventurequatresaisons.com/images/traineau2h.jpg",
    URLPointer: "/fr/laurentides/riviere-rouge/traineauachiens-vip/"
  },
  {
    name: "Raquette Tractée",
    pricing: 109,
    activityType: "winter",
    description:
      "La randonnée tractée est un sport méconnu, mais rapidement apprécié des amoureux des chiens. Facilement adaptable selon les" +
      " humeurs du groupe, le chien relié à l'humain par un bungee aidera dans les montées ou vous donnera la motivation d'aller plus vite." +
      "Praticable en toute saison, la relation entre l'humain et le chien est approfondie tout au long de la marche par une grande interraction.",
    inclusions: "-Stationnement\n" + "-Matériel pour activité\n" + "-Accès\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Lunettes de ski\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    boatPlace: "SOLO",
    departureHour: "14h",
    duration: "1h",
    picUrl: "https://www.aventurequatresaisons.com/images/randonneetractee.jpg",
    URLPointer: "/fr/laurentides/riviere-rouge/randonnee-tractee/"
  }
];

export const yourteActivities: activities[] = [
  {
    name: "Nuitée en Yourte",
    pricing: 140,
    activityType: "yourte",
    description:
      "Pour les amateurs de camping à la recherche d'un peu plus de glamour, la yourte de Mongolie offre son époustouflant exostisme le temps d'une nuit." +
      "Les lits complets déja installés permettent de profiter pleinement du site d'hébergement afin de créer vos propres souvenirs de moments magiques." +
      "Il ne reste que les repas a prévoir ! Quel cuisto se démarquera dans cette cuisine entourée d'arbres ?",
    inclusions: "-Stationnement\n" + "-Accès\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Lunettes de ski\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    boatPlace: "entre 1 et 4",
    departureHour: "11h",
    duration: "1 nuit",
    picUrl: "https://www.aventurequatresaisons.com/images/canotcampingyourte.jpg",
    URLPointer: "/fr/laurentides/riviere-rouge/nuiteeenyourte/"
  },
  {
    name: "2 Nuits en Yourte",
    pricing: 250,
    activityType: "yourte",
    description:
      "Pour les amateurs de camping à la recherche d'un peu plus de glamour, la yourte de Mongolie offre son époustouflant exostisme le temps d'une nuit." +
      "Les lits complets déja installés permettent de profiter pleinement du site d'hébergement afin de créer vos propres souvenirs de moments magiques." +
      "Il ne reste que les repas a prévoir ! Quel cuisto se démarquera dans cette cuisine entourée d'arbres ?",
    inclusions: "-Stationnement\n" + "-Accès\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Lunettes de ski\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    boatPlace: "entre 1 et 4",
    departureHour: "11h",
    duration: "2 nuits",
    picUrl: "https://www.aventurequatresaisons.com/images/yourteperchee.jpg",
    URLPointer: "/fr/laurentides/riviere-rouge/2nuiteeenyourte/"
  },
  {
    name: "3 Nuits en Yourte",
    pricing: 360,
    activityType: "yourte",
    description:
      "Pour les amateurs de camping à la recherche d'un peu plus de glamour, la yourte de Mongolie offre son époustouflant exostisme le temps d'une nuit." +
      "Les lits complets déja installés permettent de profiter pleinement du site d'hébergement afin de créer vos propres souvenirs de moments magiques." +
      "Il ne reste que les repas a prévoir ! Quel cuisto se démarquera dans cette cuisine entourée d'arbres ?",
    inclusions: "-Stationnement\n" + "-Accès\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Lunettes de ski\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    boatPlace: "entre 1 et 4",
    departureHour: "11h",
    duration: "3 nuits",
    picUrl: "https://www.aventurequatresaisons.com/images/yourtevoyageuse.jpg",
    URLPointer: "/fr/laurentides/riviere-rouge/3nuiteeenyourte/"
  }
];

// ***-------------------summer Packages-----------------------------------***
export const canoePackages: packages[] = [
  {
    tag: "13km",
    name: "descente de 13km en canot DEFAUT",
    pricing: 49,
    activityType: "summer",
    description:
      "Si vous n'êtes que de passage dans la région, ce forfait est idéal pour vous ! \n" +
      "Cette descente en eau calme vous permettra de canoter paisiblement sur la Rivière Rouge et de profiter allègrement de nos deux îles de sable blond. " +
      "\n\nNotez que cette descente de quinze kilomètres est parfaite pour tous les gens qui souhaitent une aventure douce et paysible. Avec ses paysages sauvages, la rivière-rouge vous comblera et vous inspirera longtemps après votre départ! Prenez le temps que vous souhaitez pour descendre la rivière car vous terminerez à votre voiture. " +
      "\n\nLa navette incluse se fait au début de l'activité. Un bain de soleil ou une pause pique nique sur les nombreuses plages de sable blond agrémenteront l'expérience. L'activité parfaite pour profiter pleinement d'une belle journée d'été.",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["canoe"],
    departureHour: " 10h, 11h, 13h",
    duration: 1,
    picUrl: "https://www.aventurequatresaisons.com/images/canot15km.jpg"
  },
  {
    tag: "25km",
    name: "descente de 25km en canot",
    pricing: 69,
    activityType: "summer",
    description:
      "Vous n'en n'êtes pas à votre première descente! Cette descente en eau calme vous permettra de canoter paisiblement sur la Rivière Rouge et de profiter d'un paysage grandiose dont le site du fer à cheval de sable qui vous comblera." +
      "\n\nNotez que cette descente de 25km est parfaite pour tous les gens qui souhaitent une aventure douce et paysible mais prêts à mettre la pagaie à l'effort. 25 km de beauté sauvage à ne pas manquer !" +
      "\n\nPrenez le temps que vous souhaitez pour descendre la rivière car vous terminerez à votre voiture." +
      "La navette incluse se fait au début de l'activité." +
      "Un bain de soleil ou une pause pique nique sur les nombreuses plages de sable blond agrémenteront l'expérience." +
      "L'activité parfaite pour profiter pleinement d'une belle journée d'été.",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["canoe"],
    departureHour: "9h, 10h",
    duration: 1,
    picUrl: "https://www.aventurequatresaisons.com/images/canot25km.jpg"
  },
  {
    tag: "canotCamping25",
    name: "Canot-Camping de 25km",
    pricing: 98,
    activityType: "summer",
    description:
      "Partez à l'aventure sur 25 km d'eau calme de rivière à bord de votre canot. Ce forfait permet de profiter davantage du site de campement avec moins de km a faire en canot." +
      "Muni de votre tente, installez-vous sur l'une des nombreuses plages magestueuses de la Rivière-Rouge." +
      "Connue pour ses dunes de sable blond, cette section de rivière vous offrira de nombreux paysages a couper le souffle tout au long de votre séjour. Le séjour inclu un petit rapide de classe R1 de 150 mètre",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["canoe"],
    departureHour: "9h, 10h",
    duration: 2,
    picUrl: "https://www.aventurequatresaisons.com/images/canotcamping.jpg"
  },
  {
    tag: "canotCamping32",
    name: "Canot-Camping de 32km - 3 jours",
    pricing: 139,
    activityType: "summer",
    description:
      "Partez a l'aventure sur 32 km d'eau calme de rivière à bord de votre canot. Ce forfait permet de profiter davantage du site de campement avec moins de km a faire en canot." +
      "Muni de votre tente, installez-vous sur l'une des nombreuses plages magestueuses de la Rivière-Rouge." +
      "Connue pour ses dunes de sable blond, cette section de rivière vous offrira de nombreux paysages a couper le souffle tout au long de votre séjour. Le séjour inclu un petit rapide de classe R1 de 150 mètres.",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["canoe"],
    departureHour: "9h",
    duration: 3,
    picUrl: "https://www.aventurequatresaisons.com/images/canotcamping374j.jpg"
  },
  {
    tag: "canotCamping322j",
    name: "Canot-Camping de 32km - 2 jours",
    pricing: 98,
    activityType: "summer",
    description:
      "Partez a l'aventure sur 32 km d'eau calme de rivière à bord de votre canot. Ce forfait permet de profiter davantage du site de campement avec moins de km a faire en canot." +
      "Muni de votre tente, installez-vous sur l'une des nombreuses plages magestueuses de la Rivière-Rouge." +
      "Connue pour ses dunes de sable blond, cette section de rivière vous offrira de nombreux paysages a couper le souffle tout au long de votre séjour. Le séjour inclu un petit rapide de classe R1 de 150 mètres.",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["canoe"],
    departureHour: "9h",
    duration: 2,
    picUrl: "https://www.aventurequatresaisons.com/images/canotcamping374j.jpg"
  }
];

export const kayakPackages: packages[] = [
  {
    tag: "13kmkayak",
    name: "descente de 13km en kayak",
    pricing: 49,
    activityType: "summer",
    description:
      "Si vous n'êtes que de passage dans la région, ce forfait est idéal pour vous ! \n" +
      "Cette descente en eau calme vous permettra de canoter paisiblement sur la Rivière Rouge et de profiter allègrement de nos deux îles de sable blond. " +
      "\n\nNotez que cette descente de quinze kilomètres est parfaite pour tous les gens qui souhaitent une aventure douce et paysible. Avec ses paysages sauvages, la rivière-rouge vous comblera et vous inspirera longtemps après votre départ! Prenez le temps que vous souhaitez pour descendre la rivière car vous terminerez à votre voiture. " +
      "\n\nLa navette incluse se fait au début de l'activité. Un bain de soleil ou une pause pique nique sur les nombreuses plages de sable blond agrémenteront l'expérience. L'activité parfaite pour profiter pleinement d'une belle journée d'été.",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["kayak"],
    departureHour: " 10h, 11h, 13h",
    duration: 1,
    picUrl: "https://www.aventurequatresaisons.com/images/kayak15km.jpg"
  },
  {
    tag: "25kmkayak",
    name: "descente de 25km en kayak",
    pricing: 69,
    activityType: "summer",
    description:
      "Vous n'en n'êtes pas à votre première descente! Cette descente en eau calme vous permettra de canoter paisiblement sur la Rivière Rouge et de profiter d'un paysage grandiose dont le site du fer à cheval de sable qui vous comblera." +
      "\n\nNotez que cette descente de 25km est parfaite pour tous les gens qui souhaitent une aventure douce et paysible mais prêts à mettre la pagaie à l'effort. 25 km de beauté sauvage à ne pas manquer !" +
      "\n\nPrenez le temps que vous souhaitez pour descendre la rivière car vous terminerez à votre voiture." +
      "La navette incluse se fait au début de l'activité." +
      "Un bain de soleil ou une pause pique nique sur les nombreuses plages de sable blond agrémenteront l'expérience." +
      "L'activité parfaite pour profiter pleinement d'une belle journée d'été.",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["kayak"],
    departureHour: "9h, 10h",
    duration: 1,
    picUrl: "https://www.aventurequatresaisons.com/images/kayak25km.jpg"
  },
  {
    tag: "canotCamping25kayak",
    name: "Kayak-Camping de 25km",
    pricing: 98,
    activityType: "summer",
    description:
      "Partez a l'aventure sur 25 km d'eau calme de rivière à bord de votre canot. Ce forfait permet de profiter davantage du site de campement avec moins de km a faire en canot." +
      "Muni de votre tente, installez-vous sur l'une des nombreuses plages magestueuses de la Rivière-Rouge." +
      "Connue pour ses dunes de sable blond, cette section de rivière vous offrira de nombreux paysages a couper le souffle tout au long de votre séjour. Le séjour inclu un petit rapide de classe R1 de 150 mètres.",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["kayak"],
    departureHour: "9h, 10h",
    duration: 2,
    picUrl:
      "https://scontent-yyz1-1.xx.fbcdn.net/v/t39.30808-6/288925813_5484029158294908_2560757275076730668_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=730e14&_nc_ohc=KdF8f6_irfoAX99F2Pe&_nc_ht=scontent-yyz1-1.xx&oh=00_AfATeuA9nEXTATsMoUZWgPGDtOLkeoP29DEmqN6fEdW1Qw&oe=64932A39"
  }
];

export const supPackages: packages[] = [
  {
    tag: "13kmsup",
    name: "13km en paddleboard",
    pricing: 49,
    activityType: "summer",
    description:
      "Si vous n'êtes que de passage dans la région, ce forfait est idéal pour vous ! \n" +
      "Cette descente en eau calme vous permettra de canoter paisiblement sur la Rivière Rouge et de profiter allègrement de nos deux îles de sable blond. " +
      "\n\nNotez que cette descente de quinze kilomètres est parfaite pour tous les gens qui souhaitent une aventure douce et paysible. Avec ses paysages sauvages, la rivière-rouge vous comblera et vous inspirera longtemps après votre départ! Prenez le temps que vous souhaitez pour descendre la rivière car vous terminerez à votre voiture. " +
      "\n\nLa navette incluse se fait au début de l'activité. Un bain de soleil ou une pause pique nique sur les nombreuses plages de sable blond agrémenteront l'expérience. L'activité parfaite pour profiter pleinement d'une belle journée d'été.",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["sup"],
    departureHour: " 10h, 11h, 13h",
    duration: 1,
    picUrl: "https://www.aventurequatresaisons.com/images/sup15km.jpg"
  },
  {
    tag: "25kmsup",
    name: "descente de 25km en sup",
    pricing: 69,
    activityType: "summer",
    description:
      "Vous n'en n'êtes pas à votre première descente! Cette descente en eau calme vous permettra de canoter paisiblement sur la Rivière Rouge et de profiter d'un paysage grandiose dont le site du fer à cheval de sable qui vous comblera." +
      "\n\nNotez que cette descente de 25km est parfaite pour tous les gens qui souhaitent une aventure douce et paysible mais prêts à mettre la pagaie à l'effort. 25 km de beauté sauvage à ne pas manquer !" +
      "\n\nPrenez le temps que vous souhaitez pour descendre la rivière car vous terminerez à votre voiture." +
      "La navette incluse se fait au début de l'activité." +
      "Un bain de soleil ou une pause pique nique sur les nombreuses plages de sable blond agrémenteront l'expérience." +
      "L'activité parfaite pour profiter pleinement d'une belle journée d'été.",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["sup"],
    departureHour: "9h, 10h",
    duration: 1,
    picUrl: "https://www.aventurequatresaisons.com/images/sup25km.jpg"
  }
];

export const canotCampingPackages: packages[] = [
  {
    tag: "canotCamping25",
    name: "Canot-Camping de 25km",
    pricing: 98,
    activityType: "summer",
    description:
      "Partez a l'aventure sur 25 km d'eau calme de rivière à bord de votre canot. Ce forfait permet de profiter davantage du site de campement avec moins de km a faire en canot." +
      "Muni de votre tente, installez-vous sur l'une des nombreuses plages magestueuses de la Rivière-Rouge." +
      "Connue pour ses dunes de sable blond, cette section de rivière vous offrira de nombreux paysages a couper le souffle tout au long de votre séjour. Le séjour inclu un petit rapide de classe R1 de 150 mètres.",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["canoe"],
    departureHour: "9h, 10h",
    duration: 2,
    picUrl: "https://www.aventurequatresaisons.com/images/canotcamping.jpg"
  }
];

// END-------------------summer Package-----------------------------------***

// ***-------------------winter Packages-----------------------------------***
export const sledPackages: packages[] = [
  {
    tag: "sled1H",
    name: "Une heure de traineau à chien",
    pricing: 139,
    activityType: "winter",
    description:
      "Le traineau a chiens, À 1h45 de Montréal !" +
      "La randonnée de une heures permet aux participants de conduire leur propre embarcation en alternance et d'aller voir les sentiers plus techniques." +
      "Accompagnés par un guide qui ouvre la route, les chiens sillonnent des sentiers boisés et le champ pour un parcours féérique." +
      "La neige alourdissant les branches des arbres fait un paysage incroyable digne de nos belles Laurentides." +
      "Les chiens motivés quant à eux apprécieront vos caresses à la fin de la randonnée.",
    inclusions: "-Guide" + "-Matériel" + "-Casque",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Vêtements adaptés multicouche\n" + "-Lunettes de ski\n",
    ageMinimum: 3,
    item: ["sled"],
    departureHour: "9h, 11h, 13h",
    duration: 0.1,
    picUrl: "https://www.aventurequatresaisons.com/images/traineau1h.jpg"
  }
];
export const sledVipPackages: packages[] = [
  {
    tag: "sledVIP",
    name: "Traineau à chien VIP pour 4 personnes",
    pricing: 269,
    activityType: "winter",
    description:
      "Cette nouvelle formule vous invite à venir passer la journée dehors avec nous !" +
      "Location de raquettes en avant-midi pour sillonner les sentiers ou parcourir la poudreuse hors piste." +
      "À midi, une soupe est servie dans notre yourte chauffée au poêle à bois histoire de reprendre des" +
      "forces." +
      "Randonnée d’une heure de traîneau à chiens en après-midi suite à quoi vous pourrez en apprendre" +
      "davantage sur l'univers du musher en servant, si vous le souhaitez, la soupe de viande aux chiens" +
      "après la randonnée.",
    inclusions: "-Guide" + "-Matériel" + "-Casque",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Vêtements adaptés multicouche\n" + "-Lunettes de ski\n",
    ageMinimum: 3,
    item: ["sled", "VIP"],
    departureHour: "12h",
    duration: 0.2,
    picUrl: "https://www.aventurequatresaisons.com/images/traineau2h.jpg"
  }
];
export const jorringPackages: packages[] = [
  {
    tag: "jorringBoreal",
    name: "Randonnée Tractée - La Boréale",
    pricing: 109,
    activityType: "winter",
    description:
      "La randonnée tractée est un sport méconnu, mais rapidement apprécié des amoureux des chiens." +
      "Facilement adaptable selon les humeurs du groupe, le chien relié à l'humain par un bungee aidera dans les montées ou vous donnera la motivation d'aller plus vite." +
      "Praticable en toute saison, la relation entre l'humain et le chien est approfondie tout au long de la marche par une grande interraction. ",
    inclusions: "-Guide" + "-Matériel",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Vêtements adaptés multicouche\n" + "-Lunettes de ski\n",
    ageMinimum: 3,
    item: ["jorring"],
    departureHour: "14h",
    duration: 0.1,
    picUrl: "https://www.aventurequatresaisons.com/images/randonneetractee.jpg"
  },
  {
    tag: "jorringSportive",
    name: "Randonnée Tractée - La Sportive",
    pricing: 199,
    activityType: "winter",
    description:
      "Pour une telle ballade, il faut être en forme! Disons-le. Mais si vous pouvez tenir les pas, vous aurai une journée mémorable !" +
      "La randonnée tractée est un sport méconnu, mais rapidement apprécié des amoureux des chiens." +
      "Facilement adaptable selon les humeurs du groupe, le chien relié à l'humain par un bungee aidera dans les montées ou vous donnera la motivation d'aller plus vite." +
      "Praticable en toute saison, la relation entre l'humain et le chien est approfondie tout au long de la marche par une grande interraction.",
    inclusions: "-Guide" + "-Matériel",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Vêtements adaptés multicouche\n" + "-Lunettes de ski\n",
    ageMinimum: 3,
    item: ["jorring"],
    departureHour: "14h",
    duration: 0.1,
    picUrl: "https://www.aventurequatresaisons.com/images/randotractee2h.jpg"
  }
];
// END-------------------winter Package-----------------------------------***

// ***-------------------yourte Packages-----------------------------------***

export const yourteOneNight: packages[] = [
  {
    tag: "yourte1Night",
    name: "Séjour d'une nuitée en Yourte Mongole",
    pricing: 140,
    activityType: "yourte",
    description:
      "Exotique séjour d'une nuitée en Yourte Mongole" +
      "Pour les amateurs de camping à la recherche d'un peu plus de glamour, la yourte de Mongolie offre son époustouflant exostisme le temps d'une nuit." +
      "Les lits complets déja installés permettent de profiter pleinement du site d'hébergement afin de créer vos propres souvenirs de moments magiques." +
      "Il ne reste que les repas a prévoir ! Quel cuisto se démarquera dans cette cuisine entourée d'arbres ?",
    inclusions: "-Table à pique-nique" + "-Plage, Accès à l'eau",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Effets personnel\n",
    ageMinimum: 3,
    item: ["yourte"],
    departureHour: "12h",
    duration: 100,
    picUrl: "https://www.aventurequatresaisons.com/images/canotcampingyourte.jpg"
  },
  {
    tag: "yourte2Night",
    name: "La yourte Voyageuse - 2 nuits",
    pricing: 250,
    activityType: "yourte",
    description:
      "Amicalement appellée la 'Super Ger'. Ger voulant dire Yourte en Mongole, la super Ger est une super yourte!" +
      "Une yourte mongole complètement redimensionnée afin de se mariée à nos coutumes occidentales." +
      "La yourte Voyageuse est une belle histoire de voyage dans le temps et de traversée océanique." +
      "Elle nous proviens du passé...de la mongolie...Avec une touche américaine !" +
      "Toutes nos yourtes ont une réduction pour la deuxième nuitée !",
    inclusions: "-Table à pique-nique" + "-Plage, Accès à l'eau",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Effets personnel\n",
    ageMinimum: 3,
    item: ["yourte"],
    departureHour: "12h",
    duration: 200,
    picUrl: "https://www.aventurequatresaisons.com/images/yourtevoyageuse.jpg"
  },
  {
    tag: "yourte3Night",
    name: "La yourte Perchée - 3 nuits",
    pricing: 360,
    activityType: "yourte",
    description:
      "Nul besoin de vous expliquer sont nom ! À l'abris de tout! la yourte perchée est une yourte de 21 pieds aux allures tipiques de la Mongolie. " +
      "C'est notre plus grande yourte en hébergement et elle est très apprécié pour sa proximité de la mise à l'eau." +
      "Toutes nos yourtes ont une réduction pour la deuxième et troisième nuitée !",
    inclusions: "-Table à pique-nique" + "-Plage, Accès à l'eau",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Effets personnel\n",
    ageMinimum: 3,
    item: ["yourte"],
    departureHour: "12h",
    duration: 100,
    picUrl: "https://www.aventurequatresaisons.com/images/yourteperchee.jpg"
  }
];

export const yourteTwoNight: packages[] = [];

export const yourteThreeNight: packages[] = [];

// END-------------------yourte Package-----------------------------------***

// ***-------------------Empty reservation--------------------------------***
export const emptyReservation: reservation = {
  ID: "0",
  activite: "",
  confDate: "",
  startDate: "",
  duree: 0,
  departureTime: 0,
  prename: "",
  name: "",
  nbPaxAd: 0,
  nbPaxEnf: 0,
  nbCanoe: 0,
  yourte: "aucune",
  nbKayak: 0,
  nbSUP: 0,
  courriel: "",
  tel: "",
  modePaiement: "",
  enterprise01: "",
  enterprise: "",
  specialCode: "Notes",
  pricing: 0,
  expiryMonth: 0,
  expiryYear: 0
};
// END-------------------Empty reservation--------------------------------***

// ***-------------------Empty Inventory ANY--------------------------------***
export const emptyInventory: any = {
  ID: 0,
  startDate: "",
  canoe: 0,
  kayak: 0,
  sup: 0,
  nbPersSurDepRed9: 0,
  nbPersSurDepRed10: 0,
  nbPersSurDepRed11: 0,
  nbPersSurDepRed12: 0,
  nbPersSurDepRed13: 0,
  nbPersSurDepRed14: 0,
  spotLeft9: 0,
  spotLeft10: 0,
  spotLeft11: 0,
  spotLeft12: 0,
  spotLeft13: 0,
  spotLeft14: 0,
  yourteVoyageuse: 0,
  yourteTrad: 0,
  yourtePerchee: 0
};
// END-------------------Empty Inventory ANY--------------------------------***

// ***-------------------Empty user--------------------------------***
export const emptyUser: user = {
  id: "",
  username: "",
  firstName: "",
  lastName: "",
  email: "",
  telephone: "",
  password: "",
  createdAt: 0,
  token: "",
  admin: 0
};
// END-------------------Empty user--------------------------------***

// ***-------------------Empty userLogin--------------------------------***
export const emptyUserLogin: loginUser = {
  username: "",
  password: ""
};
// END-------------------Empty userLogin--------------------------------***

// ***-------------------yourte Packages-----------------------------------***

export const yourteVente: packages[] = [
  {
    tag: "yourteOriginal",
    name: "Les yourtes de grandeures originales : 19 à 21 pieds",
    pricing: 7500,
    activityType: "yourteSell",
    description:
      "Yourte mongole 5 et 6 murs(treillis), complète avec une isolation de feutre blanc de haute qualité, avec structure de bois décorée à la main de motifs " +
      "traditionnels, un canevas résistant à l’eau, et toutes les cordes et toiles nécessaires. Cela inclut aussi la porte à double fenêtre et un plexiglas sur la " +
      "moitié avant du tonoo." +
      "\n\nDiamètre: 19, 20 et 21 pieds, hauteur au mur: 5 pieds (1.5m), hauteur centrale: 7 1/2 pieds (2.3m). Les dimensions peuvent varier quelque peu." +
      "\n\nLa yourte est complète et on peut y vivre « comme tel ». Vous pouvez réaliser la plupart des options ci-dessous " +
      "avec quelques instructions si vous êtes habile de vos mains.",
    inclusions: "",
    exclusions: "",
    stuffToBring: "",
    ageMinimum: 3,
    item: ["yourte"],
    departureHour: "",
    duration: 0,
    picUrl: "https://www.aventurequatresaisons.com/images/yourteoriginale.jpg"
  },
  {
    tag: "yourteBig",
    name: "Les Grandes Yourtes : 26 à 40 pieds et +",
    pricing: 13100,
    activityType: "yourteSell",
    description:
      "Yourte mongole 7 à 12 murs(treillis), complète avec une isolation de feutre blanc de haute qualité, " +
      "avec structure de bois décorée à la main de motifs traditionnels, " +
      "un canevas résistant à l’eau, et toutes les cordes et toiles nécessaires. Cela inclut aussi la porte à double fenêtre et " +
      "un plexiglas sur la moitié avant du tonoo." +
      "\n\nDiamètre: 26, 30 et 40 pieds. Hauteur au mur: 5 1/2 pieds (1.7m)." +
      "hauteur centrale:  9 pieds (2.8m). Les dimensions peuvent varier quelque peu. \n\nLa yourte est complète et on peut y vivre « comme tel ». " +
      "Vous pouvez réaliser la plupart des options ci-dessous  avec quelques instructions si vous êtes habile de vos mains.",
    inclusions: "",
    exclusions: "",
    stuffToBring: "",
    ageMinimum: 3,
    item: ["yourte"],
    departureHour: "",
    duration: 0,
    picUrl: "https://www.aventurequatresaisons.com/images/yourtegrande.jpg"
  },
  {
    tag: "yourteSmall",
    name: "Les Petites yourteSell : 13 et 16 pieds",
    pricing: 4500,
    activityType: "yourte",
    description:
      "Yourte mongole 3 et 4 murs(treillis), complète avec une isolation de feutre blanc de haute qualité, avec structure de bois décorée à la " +
      "main de motifs traditionnels, un canevas résistant à l’eau, et toutes les cordes et toiles nécessaires. Cela inclut aussi la porte à " +
      "double fenêtre et un plexiglas sur la moitié avant du tonoo. \n\nDiamètre: 13 et 16 pieds. Hauteur au mur: 4 1/2 pieds (1.4m)." +
      "Hauteur centrale:  7 pieds (2.1m). Les dimensions peuvent varier quelque peu. \n\nLa yourte est complète et on peut y vivre " +
      "« comme tel ». Vous pouvez réaliser la plupart des options ci-dessous  avec quelques instructions si vous êtes habile de vos mains.",
    inclusions: "",
    exclusions: "",
    stuffToBring: "",
    ageMinimum: 3,
    item: ["yourte"],
    departureHour: "",
    duration: 0,
    picUrl: "https://www.aventurequatresaisons.com/images/yourtepetite.jpg"
  },
  {
    tag: "yourteGer",
    name: "La yourte Voyageuse - 20 pieds - Super Ger",
    pricing: 9000,
    activityType: "yourteSell",
    description:
      "Amicalement appellée la « Super Ger ». Ger voulant dire Yourte en Mongole, la super Ger est une super yourte! " +
      "Une yourte mongole complètement redimensionnée afin de se mariée à nos coutumes occidentales. " +
      "Nos yourtes disponibles en hébergement sont tous en vente au printemps et quelques fois en saison! - de 10% à 40% selon l'état de la yourte. " +
      "Tous les yourtes subissent un entretient méticuleux avant la livraison. \n\nDiamètre: 20 pieds - Super Ger. Hauteur au mur: 5 pieds (1.5m) " +
      "Hauteur centrale: 9 pieds (2.7m). Les dimensions peuvent varier quelque peu. \n\nLa yourte est complète et on peut y vivre « comme tel ». " +
      "Vous pouvez réaliser la plupart des options ci-dessous  avec quelques instructions si vous êtes habile de vos mains.",
    inclusions: "",
    exclusions: "",
    stuffToBring: "",
    ageMinimum: 3,
    item: ["yourte"],
    departureHour: "",
    duration: 0,
    picUrl: "https://www.aventurequatresaisons.com/images/yourtevoyageuse.jpg"
  },

  {
    tag: "yourtePerche",
    name: "La yourte Perchée - 21 pieds",
    pricing: 8600,
    activityType: "yourteSell",
    description:
      "Nul besoin de vous expliquer sont nom ! À l'abris de tout! la yourte perchée est une yourte de 21 pieds aux allures tipiques de la Mongolie. " +
      "C'est notre plus grande yourte en hébergement et elle est très apprécié pour sa proximité de la mise à l'eau. Nos yourtes disponibles en " +
      "hébergement sont tous en vente au printemps et quelques fois en saison! - de 10% à 40% selon l'état de la yourte. Tous les yourtes subissent " +
      "un entretient méticuleux avant la livraison. \n\nDiamètre: 21 pieds. Hauteur au mur: 5 pieds (1.5m). Hauteur centrale: 7 1/2 pieds (2.3m)" +
      "Les dimensions peuvent varier quelque peu. \n\nLa yourte est complète et on peut y vivre « comme tel ». Vous pouvez réaliser la plupart des " +
      "options ci-dessous  avec quelques instructions si vous êtes habile de vos mains.",
    inclusions: "",
    exclusions: "",
    stuffToBring: "",
    ageMinimum: 3,
    item: ["yourte"],
    departureHour: "",
    duration: 0,
    picUrl: "https://www.aventurequatresaisons.com/images/yourteperchee.jpg"
  },
  {
    tag: "yourteTrad",
    name: "La yourte Traditionnelle - 19 pieds",
    pricing: 8000,
    activityType: "yourteSell",
    description:
      "Avec son diamètre de 19 pieds, la Traditionnelle est LA VRAIE yourte authentique la plus utilisées en terre Mongole. " +
      "C'est motifs typiques vous éblouirons et sont cachet conforable vous plaira sans doute !Toutes nuits additionnelles(4ieme nuit et +) " +
      "vous seront offert à 50$ / nuits. Nos yourtes disponibles en hébergement sont tous en vente au printemps et quelques fois en saison! - " +
      "de 10% à 40% selon l'état de la yourte. Tous les yourtes subissent un entretient méticuleux avant la livraison. \n\nDiamètre: 19 pieds." +
      "Hauteur au mur: 5 pieds (1.5m). Hauteur centrale:  8 pieds (2.8m). Les dimensions peuvent varier quelque peu. \n\nLa yourte est complète et on " +
      "peut y vivre « comme tel ». Vous pouvez réaliser la plupart des options ci-dessous  avec quelques instructions si vous êtes habile de vos mains.",
    inclusions: "",
    exclusions: "",
    stuffToBring: "",
    ageMinimum: 3,
    item: ["yourte"],
    departureHour: "",
    duration: 0,
    picUrl: "https://www.aventurequatresaisons.com/images/yourtetrad.jpg"
  }
];

// END-------------------yourte Package-----------------------------------***

// ***-------------------Disabling monday and Thuasday-----------------------------------***

interface DateObject {
  year: number;
  month: number;
  day: number;
}

function getMondaysAndTuesdaysForCurrentYear(): DateObject[] {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const dates: DateObject[] = [];

  for (let month = 1; month <= 12; month++) {
    for (let day = 1; day <= 31; day++) {
      try {
        const date: DateObject = { year: currentYear, month, day };
        if (
          new Date(currentYear, month - 1, day).getDay() === 1 ||
          new Date(currentYear, month - 1, day).getDay() === 2
        ) {
          if (month !== 7 && day !== 30) dates.push(date);
        }
      } catch (error) {
        continue;
      }
    }
  }

  return dates;
}

export const disabledMondayThusday = getMondaysAndTuesdaysForCurrentYear();
// END-------------------yourte Package-----------------------------------***
